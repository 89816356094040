import React from "react"
import Layout from "../components/layout/Layout"
import styles from "../styles/text-section.module.scss"
import ContactForm from "../components/general/contactForm/kontaktuForma"
import { Helmet } from "react-helmet"
import BenefitsSection from "../components/landing/benefits"

export default function PadanguMontavimas() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Variklio tepalų parinkimas ir pakeitimas gera kaina.</title>
        <meta
          name="description"
          content="Reikalingas variklio tepalų parinkimas ir keitimas? Parinksime tai, kas geriausiai tiks jūsų automobiliui, atliksime visus reikiamus darbus. Kreipkitės."
        ></meta>
      </Helmet>
      <section className={`${styles.textSection} container`}>
        <h1>Automobilio alyvos keitimas</h1>
        <p>
          Variklio tepalų keitimas yra periodinis automobilio techninio
          aptarnavimo darbas, kuriuo rūpinasi mūsų meistrai. Norint išvengti
          gedimų ir didelių išlaidų variklio remontui, svarbu pasirūpinti, kad
          tepalai būtų pakeisti laiku.
        </p>
        <p>
          Variklio alyva plauna variklį, todėl per kurį laiką joje atsiranda
          nuosėdų - sieros junginių, dėl kurių pradeda gamintis rūgštys ir alyva
          praranda savo tepimo savybes. Nepriklausomai nuo nuvažiuoto atstumo,
          alyva turi būti keičiama kas metus, nes per tokį laiką įvyksta
          reakcijos, dėl kurių alyva neatlieka savo funkcijos.
        </p>
        <p>
          Laiku atliekamas tepalų keitimas yra vienodai svarbus tiek
          dyzeliniams, tiek benzinu varomiems automobilių varikliams. Vis tik
          dyzeliniai varikliai reikalauja daugiau priežiūros, nes juose yra
          daugiau detalių, kurių veikimas priklauso nuo tinkamo alyvos sutepimo.
        </p>
      </section>
      <ContactForm />
      <section className={`${styles.textSection} container`}>
        <h2>Kainos:</h2>
        <h3>Valvoline tepalų tipų (klampumas) kainos: </h3>
        <ul>
          <li>5W30 1 Litras - 8 Eur.</li>
          <li>5W40 1 Litras - 6 Eur.</li>
        </ul>
        <h3>Tepalų keitimas:</h3>
        <ul>
          <li>Paslaugos kaina - 13 Eur.</li>
          <li>5W40 1 Litras - 6 Eur.</li>
        </ul>

        <p>
          Nežinote kokios alyvos reikia ar turite kitų klausimų? Skambinkite:{" "}
          <a href="tel:+37068845247" className={styles.callButton}>
            +37068845247
          </a>
        </p>
      </section>
      <BenefitsSection />
    </Layout>
  )
}
